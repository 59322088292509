import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import TopHero from "../components/tophero"
import SEO from "../components/seo"
import { Helmet } from 'react-helmet'
import ContactTabs from "../components/contacttabs"

class PageTemplate extends Component {

  componentDidMount() {
    window.seamlessVariables = {url:"https://pwcgov.seamlessdocs.com"};
  }

  render() {
    const landingPage = this.props.data.allNodeLandingPage.edges[0].node
    var groupMenu = this.props.data.allMenuLinkContentMenuLinkContent
    const currentPage = this.props.data.nodeContactPage
    const allAlerts = this.props.data.allNodeAlert
    const contactPage = this.props.data.allNodeContactPage
    const meta = currentPage.relationships.field_meta_data

    var i = 0
    var address = '';
    currentPage.relationships.field_contact.map(contact => {
      if(contact.__typename === 'paragraph__contact_address' && i < 1) {
        i = i + 1
        if(contact.field_address_line_2 != null) {
          address = contact.field_address + ', ' + contact.field_address_line_2
        } else {
          address = contact.field_address
        }
      }
      return contact
    });
    return (
        <Layout >
          <SEO
              title={currentPage.title}
              metaData={meta}
              contentBody={currentPage.body}
              locationOrigin={this.props.location.origin + currentPage.path.alias}
              domen={this.props.location.origin}
          />

        <div className="site-content sub-landing_page">
          {landingPage.relationships.field_hero ? (
            <TopHero landing={landingPage} menu={groupMenu} islanding="0" alerts={allAlerts} contactPage={contactPage} />
          ) : (
            null
          )}

          <section className="breadcrumb">
            <div className="container">
              <ul className="uk-breadcrumb breadcrumb-list">
                <li><Link to="/">Home</Link></li>
                {landingPage.relationships.field_parent_agency !== null && landingPage.relationships.field_parent_agency.relationships.field_hero.field_title_html ? (
                  <li><Link to={landingPage.relationships.field_parent_agency.path.alias}>{landingPage.relationships.field_parent_agency.relationships.field_hero.field_title_html.value}</Link></li>
                ) : (
                  null
                )}
                <li><Link to={landingPage.path.alias}>{landingPage.relationships.field_hero.field_title_html.value}</Link></li>
                <li className="uk-disabled">{currentPage.field_title}</li>
              </ul>
            </div>
          </section>


          <section className="contact-info">
            <div className="container">
              <h2 className="h3" dangerouslySetInnerHTML={{ __html: currentPage.field_title }} />

              <div className="uk-grid" data-uk-grid>
                <div className="uk-width-1-2@m">
                  {currentPage.body && currentPage.body.processed ? (
                    <div className="intro-box" dangerouslySetInnerHTML={{ __html: currentPage.body.processed }} />
                  ) : (
                    null
                  )}
                  {currentPage.relationships.field_contact.map((contact, index) => (
                    contact.__typename === 'paragraph__contact_address' ? (
                      <div className="address-box" key={index}>
                        <div className="address-line-1"  dangerouslySetInnerHTML={{ __html: contact.field_address }} />
                        <div className="address-line-2"  dangerouslySetInnerHTML={{ __html: contact.field_address_line_2 }} />
                        <div className="toolbox">
                          <a className="viewmap" href={"https://www.google.com/maps/search/" + contact.field_address + contact.field_address_line_2} target="_blank" rel="noreferrer">View Map</a>&nbsp;|&nbsp;
                          <a className="directions" href={"https://www.google.com/maps/dir/Current+Location/" + contact.field_address + contact.field_address_line_2} target="_blank" rel="noreferrer">Get Directions</a>
                        </div>
                      </div>
                    ) : (
                      contact.__typename === 'paragraph__contact_phone' ? (
                        <div className="phone-box" key={index}>
                          <a href={"tel:" + contact.field_phone}>{contact.field_phone}</a>
                          {contact.field_title && contact.field_title != null ? (
                            <div className="toolbox">{contact.field_title}</div>
                          ) : (
                            null
                          )}
                        </div>
                      ) : (
                        contact.__typename === 'paragraph__contact_fax' ? (
                          <div className="fax-box" key={index}>
                            <a href={"fax:" + contact.field_phone}>{contact.field_phone}</a>
                          </div>
                        ) : (
                          contact.__typename === 'paragraph__contact_email' ? (
                            <div className="email-box" key={index}>
                              <a href={"mailto:" + contact.field_email}>{contact.field_email}</a>
                            </div>
                          ) : (
                            contact.__typename === 'paragraph__contact_custom_text' ? (
                              <div className="media-contact-box" dangerouslySetInnerHTML={{ __html: contact.field_description_wysiwyg.processed }} key={index}/>
                            ) : (
                              contact.__typename === 'paragraph__contact_mobile_phone' ? (
                                <div className="mobile-box" key={index}>
                                  <a href={"tel:" + contact.field_phone}>{contact.field_phone}</a>
                                </div>
                              ) : (
                                null
                              )
                            )
                          )
                        )
                      )
                    )
                  ))}
                </div>
                <div className="uk-width-1-2@m">
                  <div className="map">
                    {address && address !== '' ? (
                      <iframe width="100%" title="map-address" height="400" src={"https://www.arcgis.com/apps/Embed/index.html?webmap=42d89f84ec4b4627ab2b2d17075e9873&search=true&searchextent=true&find=" + address+ ')'} />
                    ) : (
                      null
                    )}
                  </div>
                </div>
              </div>
              {currentPage.relationships.field_social_links.length > 0 && currentPage.relationships.field_social_links[0].field_link != null ? (
                <div className="uk-width-1-1@m social-networks">
                  <h2 className="h3">Social Media</h2>
                  <div className="uk-flex">
                    {currentPage.relationships.field_social_links.map((social, index) => (
                      social.field_link !== null ? (
                        <a className={social.field_social_network} target="_blank" rel="noreferrer" href={social.field_link.uri} key={index}>{social.field_link.title}</a>
                      ) : (
                        null
                      )
                    ))}
                  </div>
                </div>
              ) : (
                null
              )}
            </div>
          </section>

          {currentPage.relationships.field_contact_tabs && currentPage.relationships.field_contact_tabs.length > 0 ? (
            <section className="info-tabs pools">
              <ContactTabs tabs={currentPage.relationships.field_contact_tabs} />
            </section>
          ) : (
            null
          )}
          {currentPage.field_description_wysiwyg !== null ? (
            <section>
              <Helmet>
                <script async type="text/javascript" src="https://pwcgov.seamlessdocs.com/components/odoForms/js/embed-iframe/head.js"></script>
                <script async type="text/javascript" src="https://pwcgov.seamlessdocs.com/components/odoForms/js/embed-iframe/body.js"></script>
              </Helmet>
              <div className="container">
                <div dangerouslySetInnerHTML={{ __html: currentPage.field_description_wysiwyg.value}}/>
              </div>
            </section>
          ) : (
            null
          )}
        </div>
        </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!, $groupmenu: String, $landing: Int, $groupid: Int) {

    nodeContactPage(id: { eq: $id }) {
      field_title
      title
      path {
        alias
      }
      body {
        value
        processed
      }
      field_description_wysiwyg {
        value
      }
      relationships {
        field_meta_data {
          ...paragraph__meta_dataFragment
        }
        field_contact_tabs {
          field_title
          field_description_wysiwyg {
            value
            processed
          }
          relationships {
            field_pool_info {
              field_title
              field_description_wysiwyg {
                value
                processed
              }
              relationships {
                field_media_image {
                  relationships {
                    field_media_image {
                      uri {
                        url
                      }
                    }
                  }
                }
                field_contact_info {
                  ... on paragraph__contact_address {
                    field_address
                    field_address_line_2
                  }
                  ... on paragraph__contact_custom_text {
                    field_description_wysiwyg {
                      value
                      processed
                    }
                  }
                  ... on paragraph__contact_phone {
                    field_phone
                  }
                }
              }
            }
          }
        }
        field_contact {
          ... on paragraph__contact_address {
            field_address
            field_address_line_2
          }
          ... on paragraph__contact_custom_text {
            field_description_wysiwyg {
              value
              processed
            }
          }
          ... on paragraph__contact_email {
            field_email
          }
          ... on paragraph__contact_fax {
            field_phone
          }
          ... on paragraph__contact_phone {
            field_phone
            field_title
          }
          ... on paragraph__contact_mobile_phone {
            field_phone
          }
        }
        field_social_links {
          field_social_network
          field_link {
            uri
            title
          }
        }
      }
    }

    allNodeContactPage(filter: {relationships: {group_content__group_content_type_4f1d2aba752bd: {elemMatch: {relationships: {gid: {drupal_internal__id: {eq: $groupid}}}}}}}) {
      edges {
        node {
          title
          path {
            alias
          }
        }
      }
    }

    allMenuLinkContentMenuLinkContent(filter: {menu_name: {eq: $groupmenu}, enabled: {eq: true}}, sort: {fields: weight, order: ASC}) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          title
          link {
            title
            uri
          }
        }
      }
    }

    allNodeAlert(sort: {fields: field_weight, order: ASC}) {
      edges {
       ...node__alertEdgeFragment
      }
    }


    allNodeLandingPage(filter: {drupal_internal__nid: {eq: $landing}}) {
      edges {
        ...node__landing_pageEdgeFragment
      }
    }
  }
`
